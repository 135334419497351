<template>
    <default-layout class="accRecPage">
        <b-loading :active="loading"></b-loading>
        <div class="pageBody" v-if="!loading">
            <SessionPlayer
                :rec="rec"
                :initial-time="playerTime"
                @time-change="handlePlayerTimeChange"
                @event-play="handlePlayerEventPlay"
                @close-click="$router.go(-1)"
            />
            <Sidebar :rec="rec" />
        </div>
    </default-layout>
</template>

<script>
import SessionPlayer from '@/components/Player/SessionPlayer'
import Sidebar from './Sidebar'

export default {
    name: 'Rec',
    components: { Sidebar, SessionPlayer },
    data() {
        return {
            loading: true,
            rec: {},
            playerTime: parseInt(this.$route.params.time) || 0,
        }
    },
    async mounted() {
        const { id } = this.$route.params
        const { success, data } = await this.$api.get(`/v1/account/recs/${id}`)
        if (!success) {
            this.$router.push({ name: 'account.recs' })
            return this.$toast.error('Recording not found.')
        }
        this.rec = data
        this.loading = false
    },
    methods: {
        handlePlayerTimeChange(time) {
            this.$store.dispatch('player/setCurrentTime', time)
        },
        handlePlayerEventPlay(event) {
            this.$store.dispatch('player/setCurrentEvent', event)
        },
    },
}
</script>
