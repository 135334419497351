<template>
    <div class="sidebar">
        <b-tabs>
            <b-tab-item class="tab-item isDetails" label="Details">
                <!--<header>
                    <h2>
                        <b-icon icon="user"></b-icon>
                        <span class="ml-3 scoop-block">{{ rec.user }}</span>
                    </h2>
                </header>-->
                <ul>
                    <li>
                        <h4><b-icon icon="calendar-alt"></b-icon> Date</h4>
                        <p>
                            {{ rec.startDt }}
                        </p>
                        <span class="liveBadge" v-if="rec.live">
                            Live
                        </span>
                    </li>
                    <li>
                        <h4><b-icon icon="user"></b-icon> User (UID)</h4>
                        <p>
                            {{ rec.uid }}
                        </p>
                    </li>
                    <li>
                        <h4>
                            <b-icon icon="tag"></b-icon> Tags ({{
                                rec.tagCount
                            }})
                        </h4>
                        <b-taglist attached v-for="(v, k) in rec.tags" :key="k">
                            <b-tag type="is-light">
                                <strong>{{ k }}</strong>
                            </b-tag>
                            <b-tag type="is-light">{{ v }}</b-tag>
                        </b-taglist>
                    </li>
                </ul>
                <ul>
                    <li>
                        <h4>
                            <b-icon icon="map-marker-alt"></b-icon> Location
                        </h4>
                        <p>
                            {{ rec.placeLong }}
                        </p>
                    </li>
                    <li>
                        <h4><b-icon icon="globe"></b-icon> IP Address</h4>
                        <p class="scoop-block">
                            {{ rec.ip }}
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <h4><b-icon icon="mouse-pointer"></b-icon> Events</h4>
                        <p>
                            {{ rec.eventCount }}
                        </p>
                    </li>
                    <li>
                        <h4><b-icon icon="bug"></b-icon> Errors</h4>
                        <p>
                            {{ rec.errorCount }}
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <h4><b-icon icon="globe"></b-icon> Browser</h4>
                        <p>
                            {{ rec.device.browser.name }}
                            {{ rec.device.browser.ver }}
                        </p>
                    </li>
                    <li>
                        <h4><b-icon icon="window-maximize"></b-icon> OS</h4>
                        <p>
                            {{ rec.device.os.name }}
                            {{ rec.device.os.ver }}
                        </p>
                    </li>
                    <li>
                        <h4><b-icon icon="desktop"></b-icon> Resolution</h4>
                        <p>
                            {{ rec.resolution }}
                        </p>
                    </li>
                    <li>
                        <h4>User Agent</h4>
                        <p>
                            {{ rec.ua }}
                        </p>
                    </li>
                </ul>
            </b-tab-item>
            <b-tab-item :label="`Pages (${rec.pages.length})`">
                <div class="steps">
                    <div class="step">
                        <div class="stepMarker"></div>
                        <div class="stepContent"></div>
                    </div>
                    <div
                        class="step"
                        v-for="(page, index) in rec.pages"
                        :key="index"
                    >
                        <div class="stepMarker"></div>
                        <div class="stepContent">
                            <p>
                                <small>
                                    <strong>
                                        {{ page.title }}
                                    </strong>
                                </small>
                            </p>
                            <small>
                                {{ page.url }}
                                <a :href="page.url" target="_blank">
                                    <b-icon icon="external-link-alt"></b-icon>
                                </a>
                            </small>
                        </div>
                    </div>
                    <div class="step">
                        <div class="stepMarker"></div>
                        <div class="stepContent"></div>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item
                class="tab-item isConsole"
                :label="`Console (${rec.errorCount})`"
            >
                <b-collapse
                    class="card"
                    animation="slide"
                    v-for="(error, index) in errorLog"
                    :key="index"
                    :open="false"
                >
                    <div
                        slot="trigger"
                        slot-scope="props"
                        class="card-header"
                        role="button"
                    >
                        <a
                            href="#"
                            class="playBtn"
                            @click.stop.prevent="replayError(error)"
                        >
                            <i class="fas fa-play-circle"></i>
                        </a>
                        <div class="card-header-title">
                            <strong>{{ error.message }}</strong>
                            <small>{{
                                error.playerTime | millisToHhMmSs
                            }}</small>
                        </div>
                        <a class="card-header-icon">
                            <b-icon
                                :icon="props.open ? 'caret-up' : 'caret-down'"
                            ></b-icon>
                        </a>
                    </div>
                    <div class="card-content">
                        {{ error.stack }}
                    </div>
                </b-collapse>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
export default {
    name: 'Sidebar',
    props: {
        rec: {
            type: Object,
        },
    },
    computed: {
        errorLog() {
            const events = [...this.rec.events]
            events.sort((a, b) => a.timestamp - b.timestamp)
            const lastEvent = events.slice(-1)[0]
            return this.rec.errors.map(error => ({
                ...error,
                playerTime: error.timestamp
                    ? this.rec.durationMs -
                      (lastEvent.timestamp - error.timestamp)
                    : 0,
            }))
        },
    },
    mounted() {
        this.resize()
        window.addEventListener('resize', this.resize)
    },
    methods: {
        resize() {
            if (this.$el.style) {
                this.$el.style.height = `${window.innerHeight - 120}px`
            }
        },
        replayError(error) {
            window.dispatchEvent(
                new CustomEvent('sessionPlayer.goToTime', {
                    detail: { time: error.playerTime },
                })
            )
        },
    },
}
</script>
